import { BadgeEuro, Wallet } from 'lucide-react'
import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

export const Counters = ({
  options,
  setValue,
  value,
  total = 0,
  netTotal = 0,
  totalBalance = 0,
  filters,
  data = []
}) => {
  const amount = data.reduce((acc, item) => acc + item.totalPrice, 0)
  const net = data.reduce((acc, item) => acc + item.totalDriverCommission, 0)

  const totalAmount = amount / 100
  const totalNet = net / 100

  const walletAmount = data
    .filter((item) => item.paymentMethod === 'CASH')
    .reduce((acc, item) => acc + item.totalPrice, 0)

  return (
    <Styled.Container>
      <Styled.Options>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>
              {formatCurrency(totalAmount)}
            </Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL LÍQUIDO</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(totalNet)}</Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>CARTEIRA</Styled.OptionTitle>
            <Wallet color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>
              {formatCurrency(walletAmount / 100)}
            </Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
      </Styled.Options>
    </Styled.Container>
  )
}
