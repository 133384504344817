import { BadgeEuro, LineChart } from 'lucide-react'
import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

export const PartnerCounters = ({
  options,
  setValue,
  value,
  total = 0,
  netTotal = 0,
  doneBookings = 0,
  filters = {},
  data = []
}) => {
  const amount = data.reduce((acc, item) => acc + item.totalPrice, 0)
  const net = data.reduce((acc, item) => acc + item.totalDriverCommission, 0)

  const totalAmount = amount / 100
  const totalNet = net / 100
  console.log('emanuel', amount)
  return (
    <Styled.Container>
      <Styled.Options>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>
              {formatCurrency(totalAmount)}
            </Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL LÍQUIDO PARTNER</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(totalNet)}</Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TRANSFERS REALIZADOS</Styled.OptionTitle>
            <LineChart color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{data.length}</Styled.OptionValue>
            <Styled.OptionUpdate>
              {filters && filters.label ? filters.label : 'Todos os registros'}
            </Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
      </Styled.Options>
    </Styled.Container>
  )
}
