import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  rem,
  Checkbox
} from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import * as Styled from './styles'
import { ChevronRight, Copy, Pen, Trash2 } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import {
  columnAccount,
  columnBooking,
  columnDriver,
  columnPartnership
} from './grid-props'
import { toast } from 'react-toastify'
import { useClipboard } from '@mantine/hooks'
import { useState } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'

const jobColors = {
  engineer: 'blue',
  manager: 'cyan',
  designer: 'pink'
}

export function DataTableFinancialDriver({
  data,
  setDelete,
  selection,
  setSelection,
  isPartnershipFinancial = false,
  type
}) {
  const isMobile = useMediaQuery('(max-width: 500px)')

  const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    )
  const toggleAll = () =>
    setSelection((current) =>
      current?.length === data?.length ? [] : data.map((item) => item.id)
    )

  const navigate = useNavigate()
  const clipboard = useClipboard({ timeout: 500 })

  const { t } = useTranslation()

  const rows =
    data &&
    data.map((item) => {
      const selected = selection?.includes(item.id)

      const handleRowClick = (event) => {
        const isCheckbox = event.target.type === 'checkbox'
        const isActionIcon =
          event.target.closest('.action-icon') ||
          event.target.closest('.action-icon-svg')

        if (isCheckbox || isActionIcon || isPartnershipFinancial) {
          return
        }

        navigate(`/dashboard/details/${item.id}`)
      }

      const PaymentMethodMap = (paymentMethod) => {
        switch (paymentMethod) {
          case 'CREDIT_CARD':
            return 'Cartão de crédito'
          case 'MBWAY':
            return 'MB Way'
          case 'MBMULTI':
            return 'MB Multi'
          case 'CASH':
            return 'Dinheiro'
          default:
            return paymentMethod
        }
      }
      const Status = (status) => {
        switch (status) {
          case 'CANCELED':
            return 'Cancelado'
          case 'PENDING':
            return 'Pendente'
          case 'ACCEPTED':
            return 'Aceito'
          case 'IN_PROGRESS':
            return 'Em andamento'
          case 'DONE':
            return 'Finalizado'
          default:
            return status
        }
      }

      const formatCurrency = (currency) => {
        return Intl.NumberFormat('PT', {
          currency: 'EUR',
          style: 'currency'
        }).format(currency)
      }

      function formatDate(transferDate) {
        const data = new Date(transferDate)

        const opcoesFusoHorario = { timeZone: 'Europe/Lisbon' }

        data.setMinutes(
          data.getMinutes() +
            data.getTimezoneOffset() +
            (data.getTimezoneOffset() === 0 ? 0 : -60)
        )

        const dia = String(data.getDate()).padStart(2, '0')
        const mes = String(data.getMonth() + 1).padStart(2, '0')
        const ano = String(data.getFullYear()).slice(-2)
        const horas = String(
          data.toLocaleString('pt-PT', {
            ...opcoesFusoHorario,
            hour: 'numeric',
            hour12: false
          })
        ).padStart(2, '0')
        const minutos = String(data.getMinutes()).padStart(2, '0')

        return `${dia}/${mes}/${ano} - ${horas}:${minutos}`
      }

      const renderItems = () => {
        if (type === 'drivers') {
          return (
            <>
              <td>
                <Checkbox
                  color="27C7FF"
                  checked={selection?.includes(item.id)}
                  onChange={() => toggleRow(item.id)}
                />
              </td>
              <td>
                <Text fz="sm" fw={500}>
                  {item.driver?.name}
                </Text>
              </td>
              <td>
                <Text fz="sm" fw={500}>
                  {item.driver?.id}
                </Text>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {item?.vehicle?.category?.name}
                </Anchor>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalPrice / 100)}
                </Anchor>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalDriverCommission / 100)}
                </Anchor>
              </td>
              <td>
                <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
              </td>
              <td>
                <Group gap={0} justify="flex-end">
                  <ActionIcon
                    variant="subtle"
                    color="gray"
                    className="action-icon"
                    onClick={() => navigate(`/dashboard/details/${item.id}`)}
                  >
                    <ChevronRight width={24} height={24} color="#27c7ff" />
                  </ActionIcon>
                </Group>
              </td>
            </>
          )
        } else if (type === 'partnership') {
          return (
            <>
              <td>
                <Checkbox
                  color="27C7FF"
                  checked={selection?.includes(item.id)}
                  onChange={() => toggleRow(item.id)}
                />
              </td>
              {!isMobile && (
                <td>
                  <Text fz="sm" fw={500}>
                    {item.driver?.name}
                  </Text>
                </td>
              )}
              <td>
                <Text fz="sm">
                  {item.id.length > 8 ? item.id.slice(0, 8) + '...' : item.id}{' '}
                  <Copy
                    size="16"
                    style={{ cursor: 'pointer' }}
                    alt="Copy id"
                    onClick={(e) => {
                      e.stopPropagation()
                      try {
                        clipboard.copy(item.id)
                        toast.success(t('toast.copiedID'))
                      } catch {
                        toast.error(t('errorCopiedID'))
                      }
                    }}
                  />
                </Text>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {item?.vehicle?.category?.name}
                </Anchor>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalPrice / 100)}
                </Anchor>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalDriverCommission / 100)}
                </Anchor>
              </td>
              <td>
                <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
              </td>
              <td>
                <Group gap={0} justify="flex-end">
                  <ActionIcon
                    variant="subtle"
                    color="gray"
                    className="action-icon"
                    onClick={() => navigate(`/dashboard/details/${item.id}`)}
                  >
                    <ChevronRight width={24} height={24} color="#27c7ff" />
                  </ActionIcon>
                </Group>
              </td>
            </>
          )
        } else {
          return (
            <>
              <td>
                <Checkbox
                  color="27C7FF"
                  checked={selection?.includes(item.id)}
                  onChange={() => toggleRow(item.id)}
                />
              </td>
              <td>
                <Text fz="sm">
                  {item.id.length > 5 ? item.id.slice(0, 5) + '...' : item.id}{' '}
                  <Copy
                    size="16"
                    style={{ cursor: 'pointer' }}
                    alt="Copy id"
                    onClick={(e) => {
                      e.stopPropagation()
                      try {
                        clipboard.copy(item.id)
                        toast.success(t('toast.copiedID'))
                      } catch {
                        toast.error(t('errorCopiedID'))
                      }
                    }}
                  />
                </Text>
              </td>
              <td>
                <Text fz="sm">{formatDate(item.transferDate)}</Text>
              </td>
              <td className="mobile">
                <Text fz="sm">{Status(item.status)}</Text>
              </td>

              <td className="mobile">
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalPrice / 100)}
                </Anchor>
              </td>
              <td>
                <Anchor component="button" size="sm">
                  {formatCurrency(item.totalDriverCommission / 100)}
                </Anchor>
              </td>
              <td className="mobile">
                <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
              </td>
              <td>
                <Group gap={0} justify="flex-end">
                  <ActionIcon
                    variant="subtle"
                    color="gray"
                    className="action-icon"
                    onClick={() => navigate(`/dashboard/details/${item.id}`)}
                  >
                    <ChevronRight width={24} height={24} color="#27c7ff" />
                  </ActionIcon>
                </Group>
              </td>
            </>
          )
        }
      }

      return (
        <tr
          key={item.id}
          className={selected ? 'selected' : ''}
          style={{ cursor: 'pointer' }}
          // onClick={(event) => handleRowClick(event)}
        >
          {renderItems()}
        </tr>
      )
    })

  const columns =
    type === 'drivers'
      ? columnDriver
      : type === 'partnership'
      ? isMobile
        ? columnPartnership
        : columnPartnership
      : isMobile
      ? columnBooking.filter(
          (item) =>
            item.title !== 'Status' &&
            item.title !== 'Valor cobrado' &&
            item.title !== 'Método de pagamento'
        )
      : columnBooking

  return (
    <Styled.Container>
      {data && data.length > 0 ? (
        <Table verticalSpacing="sm">
          <thead>
            <tr>
              <th>
                <Checkbox
                  onChange={toggleAll}
                  checked={selection?.length === data?.length}
                  indeterminate={
                    selection?.length > 0 && selection?.length !== data?.length
                  }
                  color="27C7FF"
                />
              </th>
              {columns.map((item, index) => (
                <th style={{ fontWeight: 600 }} key={index}>
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      ) : (
        <div>Nenhum registro</div>
      )}
    </Styled.Container>
  )
}
